import axios from 'axios';
// const dfd = require("danfojs");
import defaultstate from "./index.js";

export default{
    saveFilename(context,payload){
        context.commit("saveFilename",payload)
    },
    saveDf(context,df){
        context.commit("saveDf",df)
    },
    saveSelectedTechniques(context,selected_techniques){
        context.commit("saveSelectedTechniques",selected_techniques)
    },
    async submitFile(context){
        /*
            Initialize the form data
        */
        const formData = new FormData();

        /*
            Add the form data we need to submit
        */
       formData.append("file", context.state.uploaded_filename); 

        /*
            Make the request to the POST /single-file URL
        */
        const response = await axios.post(process.env.API_URL+'/uploads',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        if (!response.data) {
            throw new Error('Unsuccessful request');
        } 
        const responses = response.data;
        context.state.doneUploading=true;
        context.state.df_columns = Object.keys(responses);
        context.commit("saveData",responses);
        
        // const csv = Papa.unparse(newArrayDataOfOjbect)
        // console.log(df)
    },
    // Techniques section
    saveTextcol(context,textcol){
        // localStorage.setItem('textcol',textcol);
        context.commit("saveTextcol",textcol)
    },
    setParamsTextAnalytics(context){
        const selected_choice = context.state.selected_techniques;
        console.log(selected_choice);
        let params_text_analytics = context.state.params_text_analytics;
        params_text_analytics.techniques = selected_choice;
        params_text_analytics.filename = context.state.uploaded_filename.name;
        params_text_analytics.text_column = context.state.textcol;
        context.commit("setParamsTextAnalytics",params_text_analytics);
    },
    async getData(context){
        const endpointUrl = process.env.API_URL+'/text_analytics/frequencies';
        const params = context.state.params_text_analytics;
        console.log(params);
        const config = {
            params: params,
        };
        const res = await axios.get( endpointUrl,config);
        if (!res.data) {
            throw new Error('Unsuccessful request');
        } 
        const responses = res.data;
        // const json_data = Object.values(responses);
        console.log(responses);
        context.state.doneSubmitting=true;
        context.commit("saveDataAfterPreprocessing",responses);
    },
    setParamsVisualizeHashtag(context,params_visualize_hashtags){
        context.commit("setParamsVisualizeHashtags",params_visualize_hashtags);
    },
    saveWords(context,words){
        context.commit("saveWords",words);
    },
    async visualize_hashtag_over_time(context){
        const endpointUrl = process.env.API_URL + '/text_analytics/visualize_hashtag';
        const params = context.state.params_visualize_hashtags;
        const config = {
            params: params,
        };
        const res = await axios.get( endpointUrl,config);
        if (!res.data) {
            throw new Error('Unsuccessful request');
        } 
        const responses = res.data;
        // const json_data = Object.values(responses);
        console.log(responses);
        context.state.doneSubmitting=true;
        context.commit("saveDataVisualizeHashtags",responses);
    },
    resetState (context) {
        // this.file="";
        // this.df=[];
        // this.df_columns=[];
        // this.$store.state.data_after_preprocessing={};
        // console.log(this.params_text_preprocessing);
        // this.textcol=null;
        // this.techniques.selected=[];
        // this.valid_techniques = false;
        // this.params_text_preprocessing={}
        localStorage.removeItem('textcol');
        context.state.doneUploading = false;
        context.state = {...defaultstate};
        context.commit('resetState',defaultstate);
        console.log(context.state);
        
    },
}