import axios from 'axios';
let next_token="";
export default{
    setParams(context,payload){
        context.commit("setParams",payload)
    },
    setQuery(context,payload){
        context.commit("setQuery",payload)
    },
    async connectToEndPoint(context){
        console.log(context.state.params_twitter)
        // const endpointUrl = 'https://test-twitter-search-45e2mx4pfa-ew.a.run.app/twitter';
        const endpointUrl = process.env.API_URL + '/twitter';
        const params = context.state.params_twitter;
        context.state.isLoading=true;
        // console.log(params);
        const config = {
            params: params,
        };
        const res = await axios.get( endpointUrl,config);
        if (!res.data) {
            throw new Error('Unsuccessful request');
            
        } 
        const responses = res.data;
        // return res.data;
        console.log(res.data);
        context.state.isLoading=false;
        context.state.finish_collecting_tweets = true;
        context.commit("saveResponse",responses);     
    },
    async connectToEndPointBis(context){
        console.log(context.state.params_twitter)
        const token = context.state.params_twitter.bearer_token;
        const endpointUrl = 'https://api.twitter.com/2/tweets/search/all';
        let params = {
            ...context.state.params_twitter,
            "next_token": next_token
        };
        delete params.bearer_token;
        console.log(params);
        const config = {
            headers: {
                "authorization": `Bearer ${token}`
            },
            params: params,
        };
        const res = await axios.get( endpointUrl,config);
        if (!res.data) {
            // return res.data;
            console.log(res.data)
            context.commit("saveResponse",{
                ...res.data})
        } else {
            throw new Error('Unsuccessful request');
        }
    },
    fullSearch(context){
        const response = context.dispatch.connectToEndPoint;
        console.log(response);
        // if (response)
    },
    resetState (context) {
        context.commit('resetState')
    },
    resetParams (context) {
        context.commit('resetParams')
    }, 
    resetResponses (context) {
        context.commit('resetResponses')
    }

}