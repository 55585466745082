<template>
    <div class="flex justify-center">
        <router-link to="/analytics/techniques" v-slot="{ href, navigate, isActive }">
            <a :href="href"
            @click="navigate('techniques')"
            class="mx-4 inline-flex border-0 py-2 px-6 focus:outline-none rounded text-lg"
            :class="[isActive
                    ? 'text-white bg-emerald-500 hover:bg-green-600 '
                    : 'text-gray-700 bg-gray-100 hover:bg-gray-200',
                    ]">
                Choose a file and techniques
              </a>
        </router-link>
        <router-link to="/analytics/dataviz" v-slot="{ href, navigate, isActive }" v-if="doneSubmitting">
            <a :href="href" 
            @click="navigate('dataviz')"
            class="mx-4 inline-flex border-0 py-2 px-6 focus:outline-none rounded text-lg"
            :class="[isActive
                    ? 'text-white bg-emerald-500 hover:bg-green-600 '
                    : 'text-gray-700 bg-gray-100 hover:bg-gray-200',
                    ]">
                Data Vizualisation
            </a>
        </router-link>
        <a v-else class="mx-4 inline-flex border-0 py-2 px-6 focus:outline-none rounded text-lg text-gray-700 bg-gray-100 hover:bg-gray-200" 
            aria-disabled="true" >Data Vizualisation</a>

    </div>
    
    <keep-alive>
        <component :is="selectedTab"></component>
    </keep-alive>
    <router-view/>
</template>

<script>
export default {
    data () {
        return {
            selectedTab:"techniques",
        }
        
    },
    computed: {
        doneSubmitting(){
            return this.$store.state.textanalytics.doneSubmitting;
        }
    }, 
    methods: {
        navigate(tab) {
            this.selectedTab = tab;
        },
    }
}
</script>
