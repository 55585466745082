<template>
<!-- v-model="values.selected" -->
    <div class="flex flex-wrap py-2" >
        <div class="w-full lg:w-4/12 px-4 ">
            <h6 class="uppercase text-pink-400 text-sm font-bold">
                Preprocessing Techniques
            </h6> 
        </div>   
        <div class="w-full lg:w-4/12 px-4 ">
            <button @click="submitTechniques"
            class=" bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm mx-8 px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Submit
            </button>
        </div>
    </div>
    <div class="flex flex-wrap py-2">
        <div v-for="(value,idx) in choices" class="w-full lg:w-4/12 px-4" :key="idx">
            <div class="relative w-full mb-3">   
                <input type="checkbox" :id="value" 
                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" 
                :value="value" :checked="checked[idx]" @change='checked[idx] = !checked[idx]'
                />
                <!-- <p>{{values.selected}}</p> -->
                <label :for="value" class="ml-2">{{value}}</label>
            </div>
        </div>
    </div>    
</template>

<script>

export default{
    props: ["prep_techniques","submitTechniques"],
    computed: {
        checked() {
            return this.prep_techniques.checked;
        }, 
        choices(){
            return this.prep_techniques.choices;
        }
    },
}
</script>
