export default{
    data(state){
        return state.data
    },
    df(state){
        return state.df;
    }, 
    prep_techniques(state){
        return state.prep_techniques;
    }, 
    data_after_preprocessing(state){
        return state.data_after_preprocessing;
    }
}