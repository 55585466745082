import {createRouter, createWebHistory} from 'vue-router';
import TwitterSearch from './pages/twitter/TwitterSearch.vue';
import Login from './pages/auth/Login.vue';
import Auth from "./components/layouts/Auth.vue";
import TextPreprocessing from './pages/textPreprocessing/TextPreprocessing.vue';
import TextAnalytics from './pages/textAnalytics/TextAnalytics.vue';
import techniques from './components/textAnalytics/techniques.vue';
import dataviz from './components/textAnalytics/dataViz.vue';

const router =createRouter(
            {
                history : createWebHistory(), 
                routes: [
                    {path:'/', redirect:"/auth/login"}, 
                    {path: '/twitter',component:TwitterSearch},
                    {
                        path: "/auth",
                        redirect: "/auth/login",
                        component: Auth,
                        children: [
                            {
                            path: "/auth/login",
                            component: Login,
                            },
                            {
                            path: "/auth/register",
                            component: null,
                            },
                        ],
                    },
                    {path: '/preprocessing',component:TextPreprocessing},
                    {
                    path: "/analytics",
                    redirect: "/analytics/techniques",
                    component: TextAnalytics,
                    children: [
                        {
                        path: "/analytics/techniques",
                        component: techniques,
                        },
                        {
                        path: "/analytics/dataviz",
                        component: dataviz,
                        }
                    ]
                    }
                ]
});

export default router 