export default{
    data(state){
        return state.data
    },
    df(state){
        return state.df;
    }, 
    textcol(state){
        return state.textcol;
    },
    techniques(state){
        return state.techniques;
    }, 
    data_after_preprocessing(state){
        return state.data_after_preprocessing;
    }
}