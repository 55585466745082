<template>
<!-- v-model="values.selected" -->
    <div class="w-full lg:w-12/12 px-4">
        <div class="relative w-full mb-3">
            <span
            class="block uppercase text-pink-400 text-sm font-bold mb-2"
            >
            {{title}}
            </span> 
        </div>
    </div>
    <div v-for="(value,idx) in values.choices" class="w-full lg:w-4/12 px-4" :key="idx">
        <div class="relative w-full mb-3">   
            <input type="checkbox" :id="label+'_'+value" 
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" 
            :value="value" :checked="values.checked[idx]" @change='values.checked[idx] = !values.checked[idx]'
            />
            <!-- <p>{{values.selected}}</p> -->
            <label :for="label+'_'+value" class="ml-2">{{value}}</label>
        </div>
    </div>
</template>

<script>

export default{
    props: ["options_field"],
    computed: {
        title() {
            return this.options_field.title;
        }, 
        values(){
            return this.options_field.values;
        }, 
        label(){
            return this.options_field.label;
        },
    },
}
</script>
