import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js"; 

const defaultState = () => {
    return { 
        uploaded_filename : '',
        df : [],
        data: {}, 
        textcol:null,
        df_columns:[],
        valid_techniques : false,
        params_text_preprocessing : {},
        data_after_preprocessing : {},
        prep_techniques:{
            choices: [
            'links_removal','rt_removal','mentions_removal','stopword_removal', 'remove_punctuations',
            'lemmatization','tokenization','pos_tagging','get_bigrams','get_trigrams'
            ],
            invalid_combinations : [ ['tokenization', 'pos_tagging'],
                            ['get_bigrams','get_trigrams']],
            checked:[true,true,true,true,true,false,false,false,false,false],
            selected:[]
        }
    }    
}
export default{
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions
}