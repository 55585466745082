<template>
    <div class="flex justify-center">
        <button 
        @click="navigate('visualize')"
        class="mx-4 inline-flex border-0 py-2 px-6 focus:outline-none rounded text-lg"
        :class="[selectedTab == 'visualize' 
                ? 'text-white bg-emerald-500 hover:bg-green-600 '
                : 'text-gray-700 bg-gray-100 hover:bg-gray-200',
                ]">
            Visualize Hashtags Over Time
        </button>
        <button @click="navigate('search')"
        class="mx-4 inline-flex border-0 py-2 px-6 focus:outline-none rounded text-lg"
        :class="[selectedTab == 'search' 
                ? 'text-white bg-emerald-500 hover:bg-green-600 '
                : 'text-gray-700 bg-gray-100 hover:bg-gray-200',
                ]">
            Search {{title}}
        </button>

    </div>
    <form class="flex lg:w-8/12 py-6 w-full flex-wrap mx-auto px-8 space-y-4 justify-center" @submit.prevent="visualizeHashtag" v-if="selectedTab=='visualize'">
        <div class="w-full">
            <label for="visualize" class="leading-7 text-base font-bold text-gray-600">Enter HASHTAGS to visualize</label>
            <input type="text" id="visualize" name="visualize" v-model="words" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
        </div>
        <div class="flex flex-wrap py-4 w-full">
            <div class="w-full lg:w-4/12"  >
                <p class="mt-2 font-bold">Choose the column containing DATE </p>
            </div>
            <div class="w-full lg:w-8/12 px-4">
                <select class="w-full rounded" v-model="date_column">
                    <option v-for="column,index in df_columns" :value="column" :key="index">{{column}}</option>
                </select>
            </div>
        </div>

        <div>
            <button class="text-white bg-emerald-500 border-0 py-2 px-4 mr-4 my-4 focus:outline-none rounded text-lg">Visualize</button>
        </div>
    </form>

    <form class="flex lg:w-8/12 py-6 w-full flex-wrap mx-auto px-8 space-y-4 justify-center" @submit.prevent="search(words)" v-if="selectedTab=='search'">
        <div class="w-full">
            <label for="search" class="leading-7 text-base font-bold text-gray-600">Search text containing {{title}} (case sensitive)</label>
            <input type="text" id="search" name="search" v-model="words" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
        </div>

        <div class="">
            <button class=" text-white bg-emerald-500 border-0 py-2 px-4 my-4 focus:outline-none rounded text-lg">Search</button>
        </div>
        
    </form>
    <div v-if="searching">
        <base-spinner></base-spinner>
    </div>
    <div class="flex flex-col w-full lg:w-12/12 " v-if="showTweetInfo">
        <div v-for="text,idx in highlight_texts" :key="idx"
        class="py-4 cursor-pointer bg-green-50 border rounded my-6" @click="toggleTweetInfo(idx)">
            <span v-html="text"/>
            <div class="cursor-pointer bg-blueGray-100 border rounded my-6"
            style="display:none;" :id="`text-${idx}`" :ref="`text-${idx}`">
            <ul >
                <li v-for="(value, name, index) in df_results[idx]" :key="index">
                    <span class="font-bold">{{ name }}: </span>
                    <span>{{ value }}</span>
                </li>
            </ul> 
            </div>   
        </div>
    </div>

    
    <div class="w-full lg:w-12/12 mb-12 xl:mb-0 px-4" v-if="chooseDateColumn">
        <card-line-chart :hashtags="words"/>
    </div>
    <keep-alive>
      <component :is="selectedTab"></component>
    </keep-alive>
</template>

<script>
import CardLineChart from "../Cards/CardLineChart.vue";
import BaseSpinner from '../ui/BaseSpinner.vue';

const dfd = require("danfojs");
export default{
    components: {
    CardLineChart,
        BaseSpinner
    },
    data() {
        return {
            words: "",
            df: [],
            textcol:"",
            df_columns:[],
            highlight_texts:[],
            searching:null,
            df_results:[],
            date_column:"",
            // title:"",
            selectedTab:"visualize",
            showTweetInfo : false,
            chooseDateColumn : false,
            params_visualize_hashtags: {}
        }
    },
    created(){
        const data = this.$store.getters['textanalytics/data'];
        console.log(data);
        const selected_choices = this.$store.state.textanalytics.selected_techniques;
        console.log("params_text_analytics " + selected_choices);

        if(selected_choices==="get_word_frequency"){
            this.title = "WORD"
        }else if(selected_choices==="get_hashtag_frequency"){
            this.title = "HASHTAG"
        }else{
            this.title = null;
        }
        console.log(this.title);
        this.df = new dfd.DataFrame(data);
        this.df_columns = Object.values(this.df.columns);
        console.log(this.df);
        // this.textcol = localStorage.getItem('textcol');
        this.textcol =this.$store.state.textanalytics.textcol;
        console.log(this.textcol)
    }, 
    methods: {
        highlight_text(df,words){
            const colors = ["bg-emerald-200","bg-lightBlue-200","bg-pink-200","bg-indigo-200","bg-purple-200"];
            let indices = [];
            let texts = df[this.textcol].values;
            console.log(texts[0]);
            for (var i = 0; i < texts.length; i++) {
                let idx=i;
                let text = texts[i];
                for (const [index,word] of words.entries()) {
                    let regex1 = new RegExp(`\\b${word}\\b`,"g");
                    let regex2 = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
                    let regex3 = new RegExp(`\\B${word}\\b`,"g");
                    if(word.search(regex2) === -1 && text.search(regex1) !== -1){
                        let highlight_word = `<span class="tooltip ${colors[index]} rounded"> ${word} </span>`;
                        // text_contain_words = text_contain_words.replace(regex, highlight_word);
                        text = text.replace(regex1, highlight_word);
                    }
                    else if(word.search(regex2) !== -1 && text.search(regex3) !== -1){
                        let highlight_word = `<span class="tooltip ${colors[index]} rounded"> ${word} </span>`;
                        // text_contain_words = text_contain_words.replace(regex, highlight_word);
                        text = text.replace(regex3, highlight_word);
                    }
                    else{
                        text=null;
                        idx=null;
                        break;
                    } 
                }
                this.highlight_texts.push(text);
                indices.push(idx);
                this.highlight_texts = this.highlight_texts.filter(x => x !== null);
                indices = indices.filter(x => x !== null);
            }
            if (this.highlight_texts.length === 0) {
                alert("No texts found for this request!");
            }else {
                let df_sub = this.df.iloc({rows: indices});
                console.log(df_sub);
                this.df_results = df_sub.to_json({ download: false });
            }
            this.searching=false;
        },
        toggleTweetInfo(idx){
            // this.showTweetInfo = ! this.showTweetInfo;
            let x = this.$refs[`text-${idx}`];
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        }, 
        search(words){
            this.highlight_texts=[];
            this.df_results =[];
            this.searching=true;
            this.showTweetInfo = false;
            console.log(this.highlight_texts.length);
            console.log(this.searching);
            const lst_words = words.split(',').map(item => item.trim());
            this.highlight_text(this.df,lst_words);
            console.log(this.searching);
            console.log(this.highlight_texts.length);
            this.showTweetInfo = true;

        }, 
        setParamsVisualizeHashtag(){
            this.params_visualize_hashtags['filename'] = this.$store.state.textanalytics.uploaded_filename.name;
            this.params_visualize_hashtags['text_column'] = this.textcol;
            this.params_visualize_hashtags['date_column'] = this.date_column;
            let hashtags = this.words.split(",")
            hashtags = hashtags.map(function(hashtag) { return hashtag.trim().replace("#",'')});
            console.log(hashtags);
            this.params_visualize_hashtags['hashtags'] = hashtags.join(",");
            this.$store.dispatch('textanalytics/setParamsVisualizeHashtag', this.params_visualize_hashtags); 
        },
        async visualizeHashtag(){
            this.chooseDateColumn=false;
            console.log(this.chooseDateColumn)
            this.setParamsVisualizeHashtag();
            // Set Params to Request 
            await this.$store.dispatch("textanalytics/visualize_hashtag_over_time");
            this.chooseDateColumn = true;

        }, 
        navigate(tab) {
            this.selectedTab = tab;
            if(this.selectedTab==="visualize"){
                this.showTweetInfo=false;
                this.chooseDateColumn=true;
            }else if(this.selectedTab==="search"){
                this.chooseDateColumn=false;
                this.showTweetInfo=true;
            }
            console.log(this.selectedTab);
            console.log(this.chooseDateColumn);
            console.log(this.showTweetInfo);
        },
    }
}
</script>
