<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
        <admin-navbar pageName="Text Preprocessing"/>
        <header-text-preprocessing
        title="Text Preprocessing"
        :description="description"/>
    </div>
    
    <div class="relative md:pt-12 pb-12 pt-8 px-6 md:ml-64">
      <main-text-preprocessing/>
    </div>
    
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar/Sidebar.vue";
import AdminNavbar from "../../components/Navbars/AdminNavbar.vue";
import HeaderTextPreprocessing from "../../components/Headers/HeaderTextPreprocessing.vue";
import mainTextPreprocessing from "../../components/textPreprocessing/mainTextPreprocessing.vue";

// import CardProfile from "@/components/Cards/CardProfile.vue";

export default {
  computed: {
    description() {
      return "Different text preprocessing technics : " + "\n" +"pos "
    }
  },
  components: {
    Sidebar,
    AdminNavbar,
    mainTextPreprocessing,
    HeaderTextPreprocessing,
    // CardProfile,
  },
};
</script>
