<template>
    <div class="flex flex-wrap py-8" v-if="!doneUploading">
        <div class="w-full lg:w-9/12 px-4 ">
          <label
            class="flex flex-col items-center px-4 py-6 bg-green-100 rounded-md shadow-md tracking-wide uppercase border border-blue 
            cursor-pointer ease-linear transition-all duration-150">
            <i class="fas fa-cloud-upload-alt fa-3x "></i>
            <span class="mt-2 text-base leading-normal">Select a file and click Upload</span>
            <input type='file' class="hidden" ref="file" v-on:change="handleFileUpload()"/>
            </label>
            <div class="flex flex-col items-center px-4 py-2 bg-white rounded-md shadow-md border border-emerald-700" v-if="this.file!=''">
                <span class="mt-2 text-base font-bold">Selected File : {{this.file.name}}</span>
            </div>
            <!-- <p class="w-full" v-if="this.file!=''">Selected File : {{this.file.name}}</p> -->
        </div>
        <div class="w-full lg:w-3/12 px-4">
            <button
            class="bg-emerald-500 mt-10 mr-4 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click="submitFile()"
            >Upload
            </button>
            
        </div>
    </div>
    
    <div class="flex justify-around" id="uploadNewFile">
        <div v-if="doneUploading">
            <button class=" bg-red-400 mt-6 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" 
            @click="uploadNewFile()">
            Upload New File
            </button>
        </div>
    </div>
    <div>
        <!-- <div class="w-full lg:w-12/12" id="df1" v-if="!hiddenDf"> -->
        <div class="w-full lg:w-12/12" id="df1">
            <!-- <div id="df1" style="display:inline-block" v-if="!hiddenDf"></div>
            <div id="df1" style="display:none" v-else></div> -->
        </div>
    </div>
    
    <div class="flex flex-wrap py-4" 
    v-if="doneUploading"
    id="text_column" 
    ref="text_column">
        <div class="flex flex-wrap py-4 w-full"  v-if="textcol === null" >
            <div class="w-full lg:w-4/12 px-4"  
            >
                <p class="mt-2">Choose the column containing text </p>
            </div>
            <div class="w-full lg:w-8/12 px-4">
                <select class="w-full rounded" v-model="textcol">
                    <option v-for="column,index in df_columns" :value="column" :key="index">{{column}}</option>
                </select>
            </div>
        </div>
        <div class="py-4" id="techniques" 
        ref="techniques" >
            <techniques-checkbox 
            :prep_techniques="techniques" 
            :submitTechniques="submitTechniques"
            />
    </div> 
    </div>
    
</template>

<script>
// import axios from 'axios';
// import Papa from '../../../node_modules/papaparse/papaparse.js';
import techniquesCheckbox from './techniquesChecbox.vue';
const dfd = require("danfojs");

export default {
    components: {
        techniquesCheckbox,
    },
    data() {
        return {
            file:"",
            df:[],
            df_columns:[],
            // data: {},
            // textcol:null,
            params_text_preprocessing : {},
            header_style : {                   
                fill: { color: ['#50C98E'] },                    
                font: { family: "Arial", size: 15, color: "white" } ,
                width: 1200,
                },
            cell_style : { 
                line: { color: "black", width: 1 }                
                },    
        }
    },
    computed: {
        data() {
            return this.$store.getters['textanalytics/data'];
        },
        // df_columns(){
        //     return this.$store.state.textanalytics.df_columns;
        // },
        techniques(){
            return this.$store.getters['textanalytics/techniques'];
        }, 
        data_after_preprocessing(){
            return this.$store.getters['textanalytics/data_after_preprocessing'];
        }, 
        doneUploading(){
            return this.$store.state.textanalytics.doneUploading;
        }
    },
    mounted() {
        console.log(this.doneUploading);
        if (this.checkIfTextcolIsNotEmpty()) {
            // this.textcol = localStorage.getItem('textcol');
            this.textcol =this.$store.state.textanalytics.textcol
        }else{
            this.textcol = null;
            console.log(this.textcol);
        }
        let externalScript = document.createElement('script');
        externalScript.setAttribute('src', 'https://cdn.plot.ly/plotly-2.2.0.min.js');
        document.head.appendChild(externalScript);
        // this.scroll()
    },
    methods:{
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
            this.$store.dispatch('textanalytics/saveFilename',this.file);            
        }, 
        checkIfTextcolIsNotEmpty() {
            // return this.textcol= !!localStorage.getItem('textcol')
            return this.textcol= !!this.$store.state.textanalytics.textcol
        },
        uploadNewFile(){
            this.$store.state.textanalytics.doneUploading = false;
            this.$store.dispatch('textanalytics/resetState');
            // this.file="";
            // this.df=[];
            // this.df_columns=[];
            // this.$store.state.data_after_preprocessing={};
            // console.log(this.params_text_preprocessing);
            // this.textcol=null;
            // this.techniques.selected=[];
            // this.valid_techniques = false;
            // this.params_text_preprocessing={};
            
        },
        json_to_df(data) {
            const json_data = Object.values(data);
            const df = new dfd.DataFrame(json_data);
            return df
        },
        scroll(id) {  
            const el = document.getElementById(id);
            el.scrollIntoView({behavior: "smooth"});
        },
        async submitTechniques(){
            //Get textcol
            this.$store.dispatch('textanalytics/saveTextcol',this.textcol);
            console.log(this.textcol);

            // Set Params to Request 
            this.$store.dispatch('textanalytics/setParamsTextAnalytics'); 
            const params_text_analytics = this.$store.state.textanalytics.params_text_analytics;
            console.log("params_text_analytics " + params_text_analytics);

            // Call Request 
            await this.$store.dispatch('textanalytics/getData'); 
            this.$router.replace('/analytics/dataviz');
            
            
        },
        async submitFile(){
            console.log(this.techniques);
            // Get Json data 
            await this.$store.dispatch('textanalytics/submitFile');
            console.log(this.data);
            
            //Display Dataframe 
            const df = new dfd.DataFrame(this.data);
            df.plot("df1").table({ header_style: this.header_style, cell_style: this.cell_style });
            
            const columns_df = Object.values(df.columns);
            console.log(columns_df);
            this.df_columns = columns_df;
            if (this.doneUploading){
                this.scroll("text_column");
            }
            
         },
        downloadCsvAfterPrep(){
            let df = new dfd.DataFrame(this.data_after_preprocessing);
            dfd.to_csv(df, { fileName: "testOut.csv", download: true});
        }
    }, 
    
    
}
</script>
<style>

</style>