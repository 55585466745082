<template>
  <!-- Header -->
  <div class="relative bg-emerald-400 md:pt-32 pb-12 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <div v-if="isLoading && responses.length === 0">
            <h3 class="text-center text-white uppercase font-bold text-base">Collecting Tweets .... </h3>
            <h4 class="text-center">{{query}} </h4>
            <base-spinner></base-spinner>
        </div>
        <!-- Card stats -->
        <div class="flex flex-wrap"  v-if="finish_collecting_tweets && responses.length > 0">
          <div class="w-full lg:w-4/12 xl:w-4/12 px-4 mb-8">
            <card-stats
              statSubtitle="Total # of tweets"
              :statTitle="responses.length.toString()"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full lg:w-4/12 xl:w-4/12 px-4 mb-8">
            <card-stats
            statSubtitle="JSON file"
            statTitle="Click the icon"
            statIconName="fas fa-file-download"
            statIconColor="bg-pink-500" 
            :clickEvent="downloadJson">
            <!-- <button @click="downloadJson"
            class="uppercase
            font-bold py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md ring
            ring-pink-500 ring-offset-4 bg-pink-500 hover:bg-pink-600 text-white focus:outline-none focus:ring-2  focus:ring-emerald-500">
            Download JSON
            </button> -->
            </card-stats> 
            
          </div>

          <div class="w-full lg:w-4/12 xl:w-4/12 px-4 mb-8">
            <card-stats
            statSubtitle="CSV file"
            statTitle="Click the icon"
            statIconName="fas fa-file-download"
            statIconColor="bg-purple-500" 
            :clickEvent="downloadCsv">
            <!-- <button @click="downloadJson"
            class="uppercase
            font-bold py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md ring
            ring-pink-500 ring-offset-4 bg-pink-500 hover:bg-pink-600 text-white focus:outline-none focus:ring-2  focus:ring-emerald-500">
            Download JSON
            </button> -->
            </card-stats> 
            
          </div>
          <!-- <div class="w-full lg:w-3/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="PERFORMANCE"
              statTitle="49,65%"
              statArrow="up"
              statPercent="12"
              statPercentColor="text-emerald-500"
              statDescripiron="Since last month"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-500"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import Papa from '../../../node_modules/papaparse/papaparse.js';
import flat from '../../../node_modules/flat/index.js';
export default {
  computed: {
    isLoading() {
      return this.$store.getters['twitter/isLoading']
    }, 
    responses() {
      return this.$store.getters['twitter/responses']
    }, 
    finish_collecting_tweets() {
      return this.$store.getters['twitter/finish_collecting_tweets']
    }, 
    query() {
      return this.$store.state.twitter.query
    }
  },
  components: {
    CardStats,
  },
  methods: {
    downloadJson() {
      // credit: https://www.bitdegree.org/learn/javascript-download
      let text = JSON.stringify(this.responses, null, 4);
      let filename = 'results.json';
      let element = document.createElement('a');
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);     
  },
  downloadCsv() {
      // credit: https://www.bitdegree.org/learn/javascript-download
      let filename = 'results.csv';
      // let text = flatten(this.responses);
      let text = Papa.unparse(this.responses.map(flat.flatten));
      console.log(text);
      
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element); 
    },
  }
};
</script>
