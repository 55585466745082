<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700"
  >
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
            Overview
          </h6>
          <h2 class="text-white text-xl font-semibold">
            Hashtags Frequencies Over Time
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto">
      <!-- Chart -->
      <div class="relative h-350-px">
        <canvas id="line-chart"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import {Chart} from 'chart.js';

export default {
  props:["hashtags"],
  data() {
    return {
      data_visualize_hashtags : {},
    }
  },
  // created() {
  //   this.data_visualize_hashtags = this.$store.state.textanalytics.data_visualize_hashtags;
  //   console.log(this.data_visualize_hashtags);
  //   console.log(this.labels);
  //   console.log(this.count);
  // },
  computed: {
    labels() {
      const timestamps = Object.assign({}, this.data_visualize_hashtags["Timestamps"]);
      return Object.values(timestamps);
    }, 
    count() {
      const count = Object.assign({}, this.data_visualize_hashtags["count"]);
      return Object.values(count);
    }
  },

  mounted: function () {
    this.data_visualize_hashtags = this.$store.state.textanalytics.data_visualize_hashtags;
    console.log(this.data_visualize_hashtags);
    this.$nextTick(function () {
      var config = {
        type: "line",
        data: {
          labels:this.labels,
          datasets: [
            // {
            //   label: "#Macron,#Vaccination",
            //   backgroundColor: "#4c51bf",
            //   borderColor: "#4c51bf",
            //   data: [65, 78, 66, 44, 56, 67, 75],
            //   fill: false,
            // },
            {
              label: this.hashtags,
              fill: false,
              backgroundColor: "#fff",
              borderColor: "#fff",
              data: this.count,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Sales Charts",
            fontColor: "white",
          },
          legend: {
            labels: {
              fontColor: "white",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Month",
                  fontColor: "white",
                },
                gridLines: {
                  display: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.3)",
                  zeroLineColor: "rgba(0, 0, 0, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                  fontColor: "white",
                },
                gridLines: {
                  borderDash: [3],
                  borderDashOffset: [3],
                  drawBorder: false,
                  color: "rgba(255, 255, 255, 0.15)",
                  zeroLineColor: "rgba(33, 37, 41, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      var ctx = document.getElementById("line-chart").getContext("2d");
      window.myLine = new Chart(ctx, config);
    });
  },
};
</script>
