<template>
  <div class="py-4">
    <div class="flex flex-wrap justify-center">
        <!-- <div class="w-full lg:w-12/12 mb-12 xl:mb-0 px-4" id="df1">
        
        </div> -->
        <div class="w-full lg:w-8/12 px-4">
          <card-frequencies 
          title="Frequencies"
          :counts_dict="data_after_preprocessing"/>
        </div>

        <!-- Card Search Word  -->
        <div class="w-full lg:w-12/12 px-4 mb-12 xl:mb-0 px-4">
          <card-search />
        </div>

        <!-- <div class="w-full lg:w-8/12 mb-12 xl:mb-0 px-4">
          <card-line-chart />
        </div> -->
        <div class="w-full lg:w-4/12 px-4">
          <card-bar-chart />
        </div>
      </div>
      <div class="flex flex-wrap mt-4">
        
        <div class="w-full lg:w-4/12 px-4">
          <card-frequencies 
          title="Frequencies"
          :counts_dict="data_after_preprocessing"/>
        </div>
      </div>
  </div>
</template>
<script>
// import CardLineChart from "../Cards/CardLineChart.vue";
import CardBarChart from "../Cards/CardBarChart.vue";
import CardSearch from "../Cards/CardSearch.vue";
import CardFrequencies from "../Cards/CardFrequencies.vue";
const dfd = require("danfojs");
export default {
  name: "dashboard-page",
  components: {
    // CardLineChart,
    CardBarChart,
    CardSearch,
    CardFrequencies,
  },
  mounted() {
      // this.displayDf();
  },
  computed : {
      data_after_preprocessing(){
        return this.$store.getters['textanalytics/data_after_preprocessing'];
    }
  }, 
  methods: {
      displayDf () {
        console.log(this.data_after_preprocessing);
        const df = new dfd.DataFrame(this.data_after_preprocessing);
        df.print();
        const sub_df = df.loc({ rows: [`:`], columns: ["count", "word"] });
        sub_df.plot("df1").table();
      }
  }
};
</script>
