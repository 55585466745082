<template>
<!-- v-model="values.selected" -->
    <div class="flex flex-wrap py-2" >
        <div class="w-full lg:w-4/12 px-4 ">
            <h6 class="uppercase text-pink-400 text-sm font-bold">
                Preprocessing Techniques
            </h6> 
        </div>   
        <div class="w-full lg:w-4/12 px-4 ">
            <button @click="submitTechniques"
            class=" bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm mx-8 px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Submit
            </button>
        </div>
    </div>
    <!-- <div class="flex flex-wrap py-2" v-if="textcol !== null">
        <div v-for="(value,idx) in choices" class="w-full lg:w-4/12 px-4" :key="idx">
            <div class="relative w-full mb-3">   
                <input type="radio" :id="value" 
                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" 
                name="techniques"
                :value="value" :checked="checked[idx]"
                />
                <label :for="value" class="ml-2">{{value}}</label>
            </div>
        </div>
    </div>     -->
    <div class="flex  py-2 justify-center">
        <div v-for="(value,idx) in choices" class="px-4" :key="idx">
            <div class=" mt-3 mb-3">   
                <input type="radio" :id="value" 
                class="hidden" 
                name="techniques"
                :value="value" v-model="selected_techniques"
                @change="saveSelectedTechniques()"
                />
                <label :for="value" class="flex items-center cursor-pointer text-base">
                    <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"/>
                    {{value}}</label>
                
            </div>
        </div>
    </div>    
</template>

<script>

export default{
    props: ["prep_techniques","submitTechniques"],
    data(){
        return {
            selected_techniques: "",
        }
    },
    computed: {
        checked() {
            return this.prep_techniques.checked;
        }, 
        choices(){
            return this.prep_techniques.choices;
        }
    },
    methods: {
        saveSelectedTechniques () {
            const selected_choice = this.selected_techniques;
            this.$store.dispatch('textanalytics/saveSelectedTechniques',selected_choice)
        }
    }
}
</script>


<style scoped>
input[type="radio"] + label span {
    transition: background .2s,
      transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
  transform: scale(1.2);
} 

input[type="radio"]:checked + label span {
  background-color: rgba(52, 211, 153, 1); 
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label{
   color: #3490DC; 
}
</style>