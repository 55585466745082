import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js"; 

const defaultState = () => {
    return { 
        uploaded_filename : '',
        df : [],
        doneUploading:false,
        doneSubmitting:false,
        data: {}, 
        textcol:"",
        df_columns:[],
        params_text_analytics : {},
        params_visualize_hashtags: {},
        date_column : "",
        data_after_preprocessing: {},
        data_visualize_hashtags:{},
        // hashtag or word
        words:"", 
        selected_techniques:"",
        techniques:{
            choices: [
            'get_word_frequency','get_hashtag_frequency','get_user_replies_frequency'
            ],
            // checked:[false,false,false],
            selected:[]
        }
    }    
}
export default{
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions
}