<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
        <admin-navbar pageName="Text Analytics"/>
        <header-text-preprocessing
        title="Text Analytics"
        :description="description"/>
    </div>
    
    <div class="relative md:pt-12 pb-12 pt-8 px-6 md:ml-64">
      <main-text-analytics/>
    </div>
    
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar/Sidebar.vue";
import AdminNavbar from "../../components/Navbars/AdminNavbar.vue";
import HeaderTextPreprocessing from "../../components/Headers/HeaderTextPreprocessing.vue";
import mainTextAnalytics from "../../components/textAnalytics/mainTextAnalytics.vue";

// import CardProfile from "@/components/Cards/CardProfile.vue";

export default {
  computed:{
    description() {
      return "Create different graphs and charts to analyze the text: " + "\n" 
      + "Word Frequency, Hashtag Frequency, etc..." + "\n" 
    }
  },
  components: {
    Sidebar,
    AdminNavbar,
    HeaderTextPreprocessing,
    mainTextAnalytics
    // CardProfile,
  },
};
</script>
