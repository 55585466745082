import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js"; 

function defaultState() {
    return { 
            query:'',
            bearer_token:'',
            retweets:'',
            params_twitter : {
                "max_results":10,
            },
            isLoading:false,
            finish_collecting_tweets:false,
            responses:[],
            options_fields : [
                {
                    title:"Expansions Fields",
                    label:"expansions",
                    key_params:"expansions",
                    values:{
                        choices: ['author_id','in_reply_to_user_id','geo.place_id','referenced_tweets.id'],
                        checked: [true,true,true,true],
                        selected:[]
                    }
                }, 
                {
                    title:"Tweets Fields",
                    label:"tweet_fields",
                    key_params:"tweet.fields",
                    values: {
                        choices: [
                        'id','text','created_at','lang','author_id',
                        'public_metrics','conversation_id','in_reply_to_user_id',
                        'geo','referenced_tweets',
                        'reply_settings','source'
                        ],
                        checked:[true,true,true,true,true,true,false,false,false,false,false],
                        selected:[]
                    }
                },
                {
                    title:"User Fields",
                    label:"user_fields",
                    key_params:"user.fields",
                    values:{
                        choices: ['id','name','username','created_at','description','public_metrics','verified'],
                        checked:[true,true,true,false,false,false,false],
                        selected:[]
                    }
                },
                {
                    title:"Place Fields",
                    label:"place_fields",
                    key_params:"place.fields",
                    values:{
                        choices: ['full_name','id','country','country_code','geo','name','place_type'],
                        checked:[false,false,false,false,false,false,false],
                        selected:[]
                    }
                }    
            ],
            accounts_query: [
                {title:"From these accounts",label:'from_account',val:''},
                {title:"To these accounts",label:'to_account',val:''},
                {title:"Mentionning accounts",label:'mentionning_accounts',val:''},
            ],
            words_query : [
                {title:"All of these words",label:'all_these_words',val:''},
                {title:"Any of these words",label:'any_of_these_words',val:''},
                {title:"This exact phrase",label:'exact_phrase',val:''},
                {title:"None of these words",label:'none_of_these_words',val:''},
                {title:"These hashtags",label:'hashtags',val:''},
            ]

    };
}
export default{
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions
}