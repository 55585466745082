import {createStore} from 'vuex';

import twitterModule from "./modules/twitter/index.js";
import textPreprocessing from "./modules/text_preprocessing/index.js";
import textAnalytics from "./modules/text_analytics/index.js";
const store = createStore({
    modules: {
        twitter: twitterModule,
        textprep : textPreprocessing,
        textanalytics: textAnalytics,
        // requests: requestsModule,
        // auth: authModule,
    }
}); 
export default store; 