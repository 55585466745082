import defaultstate from "./index.js";
export default{
    saveFilename(state,payload){
        state.uploaded_filename = payload
    },
    saveData(state,payload){
        state.data = payload
    },
    saveDf(state,payload){
        state.df = payload
    }, 
    saveTextcol(state,payload){
        state.textcol = payload
    },
    setValidTechniques(state,payload){
        state.valid_techniques = payload
    }, 
    setParamsTextPreprocessing(state,payload){
        Object.assign(state.params_text_preprocessing, payload)
    }, 
    saveDataAfterPreprocessing(state,payload){
        state.data_after_preprocessing=payload
    }, 
    resetState (state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, defaultstate)
      }
    
}