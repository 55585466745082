export default{
    saveFilename(state,payload){
        state.uploaded_filename = payload
    },
    saveData(state,payload){
        state.data = payload
    },
    saveDf(state,payload){
        state.df = payload
    }, 
    saveTextcol(state,payload){
        state.textcol = payload
    },
    setParamsTextAnalytics(state,payload){
        state.params_text_analytics = payload
    }, 
    saveDataAfterPreprocessing(state,payload){
        state.data_after_preprocessing=payload
    }, 
    saveSelectedTechniques(state,payload){
        state.selected_techniques = payload
    },

    setParamsVisualizeHashtags(state,payload){
        state.params_visualize_hashtags = payload
    },
    saveDataVisualizeHashtags(state,payload){
        state.data_visualize_hashtags = payload
    },
    saveWords(state,payload){
        state.words = payload
    },
    resetState (state,defaultstate) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, defaultstate)
    }
    
}