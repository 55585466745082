<template>
  <div>
    <sidebar />
      <div class="relative md:ml-64 bg-blueGray-100">
        <admin-navbar pageName="Twitter Search"/>
        <header-twitter />
        <div class="px-4 my-4 md:px-10 mx-auto w-full -m-24">
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <form-twitter/>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import formTwitter from "../../components/Forms/Twitter/formTwitter.vue";
import Sidebar from "../../components/Sidebar/Sidebar.vue";
import AdminNavbar from "../../components/Navbars/AdminNavbar.vue";
import HeaderTwitter from "../../components/Headers/HeaderTwitter.vue";


// import CardProfile from "@/components/Cards/CardProfile.vue";

export default {
  components: {
    formTwitter,
    Sidebar,
    AdminNavbar,
    HeaderTwitter
    // CardProfile,
  },
};
</script>
