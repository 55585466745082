<template>
  <!-- Header -->
  <div class="relative bg-emerald-400 md:pt-32 pb-12 pt-12 
  flex flex-col min-w-0 break-words xl:mb-0 ">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div
      class="relative break-words mb-6 xl:mb-0 "
      >
        <div class="text-center ">
          <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">{{title}}</h1>
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"><pre>{{description}}</pre></p>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
// import CardStats from "@/components/Cards/CardStats.vue";
// import Papa from '../../../node_modules/papaparse/papaparse.js';
// import flat from '../../../node_modules/flat/index.js';
export default {
  props:['title','description'],
  // components: {
  //   CardStats,
  // },
  methods: {
    
  }
};
</script>
