import { createApp } from 'vue';

import App from './App.vue';
import router from './router.js'; 
import BaseSpinner from './components/ui/BaseSpinner.vue';


// import {VuePapaParse} from 'vue-papa-parse';
// import * as Papa from 'papaparse';


import "./assets/styles/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import store from './store/index.js';
const VueScrollTo = require('vue-scrollto');
const app = createApp(App); 

app.use(router);
app.use(store);
app.use(VueScrollTo);
// app.use(VuePapaParse);
app.component('base-spinner',BaseSpinner);
app.mount("#app");
