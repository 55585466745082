import axios from 'axios';
// const dfd = require("danfojs");

export default{
    saveFilename(context,payload){
        context.commit("saveFilename",payload)
    },
    saveDf(context,df){
        context.commit("saveDf",df)
    },
    async submitFile(context){
        /*
            Initialize the form data
        */
        const formData = new FormData();

        /*
            Add the form data we need to submit
        */
       formData.append("file", context.state.uploaded_filename); 

        /*
            Make the request to the POST /single-file URL
        */
        const response = await axios.post(process.env.API_URL+'/uploads',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        if (!response.data) {
            throw new Error('Unsuccessful request');
        } 
        const responses = response.data;
        context.commit("saveData",responses)
        
        // const csv = Papa.unparse(newArrayDataOfOjbect)
        // console.log(df)
    },
    // Techniques section
    saveTextcol(context,textcol){
        context.commit("saveTextcol",textcol)
    },
    getPrepTechniques(context){
        const checked = context.state.prep_techniques.checked;
        const selected_choices = context.state.prep_techniques.selected;
        const choices = context.state.prep_techniques.choices;
        // Loop checked => if checked => push value to selected 
        checked.forEach((val,index) => {
            if (val) {
                selected_choices.push(choices[index]);
            }
        });
    },
    checkTechniques(context){
        const invalid_combinations = context.state.prep_techniques.invalid_combinations;
        const selected_choices = context.state.prep_techniques.selected;
        for (const invalid_combination of invalid_combinations) {
            if (invalid_combination.every(i => selected_choices.includes(i))) {
                alert("Aïe aïe aïe!! " + invalid_combination[0].toUpperCase() + " and " + invalid_combination[1].toUpperCase()
                + " can't not be chosen simultaneously");
                break;
            }else{
                if(invalid_combinations.indexOf(invalid_combination) !== (invalid_combinations.length - 1) ){
                    continue;
                }
                else{
                    context.state.valid_techniques = true;
                }                     
            }
        }
    },
    setParamsTextPreprocessing(context){
        const valid_techniques = context.state.valid_techniques; 
        const selected_choices = context.state.prep_techniques.selected;
        let params_text_preprocessing = context.state.params_text_preprocessing;
        if(valid_techniques){
            let lst_selected_choices = [];
            selected_choices.forEach(selected_choice=>{
                lst_selected_choices.push(selected_choice)
            })
            params_text_preprocessing.filename = context.state.uploaded_filename.name;
            params_text_preprocessing.text_column = context.state.textcol;
            params_text_preprocessing.techniques=lst_selected_choices.join(',');
            context.commit("setParamsTextPreprocessing",params_text_preprocessing);
        }
    },
    async getDataAfterPreprocessing(context){
        const endpointUrl = process.env.API_URL + '/text_preprocessing';
        const params = context.state.params_text_preprocessing;
        const config = {
            params: params,
        };
        const res = await axios.get( endpointUrl,config);
        if (!res.data) {
            throw new Error('Unsuccessful request');
        } 
        const responses = res.data;
        // const json_data = Object.values(responses);
        console.log(responses);
        context.commit("saveDataAfterPreprocessing",responses);
        context.state.params_text_preprocessing={};
        context.state.prep_techniques.techniques=[];
    },
    resetState (context) {
        context.commit('resetState');
      },
}