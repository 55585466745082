<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded h-32"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            {{title}}
          </h3>
        </div>
        <!-- <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button>
        </div> -->
      </div>
    </div>
    <div class="relative h-350-px overflow-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse ">
        <thead class="thead-light">
          <tr >
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              {{headers[1]}}
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              {{headers[0]}}
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="word_count,index in words_counts" :key="index">
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              {{word_count[0]}}
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{word_count[1]}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title","counts_dict"],
  data() {
    return {
        words_counts:[],
    }
  },
  computed: {
    headers() {
      return Object.keys(this.counts_dict)
    }, 
    frequencies() {
      return Object.values(this.counts_dict)[0];
    },
    words() {
      return Object.values(this.counts_dict)[1];
    }
  }, 
  methods: {
    createWordCountsArr(){
      this.words.forEach((word,index) => {
        const count = this.frequencies[index];
        this.words_counts.push([word,count]);
      });
    }
  }, 
  mounted() {
    this.createWordCountsArr();
  }
}
</script>
