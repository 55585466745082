<template>
    <div class="flex flex-wrap py-8" v-if="!doneUploading">
        <div class="w-full lg:w-9/12 px-4 ">
          <label
            class="flex flex-col items-center px-4 py-6 bg-green-100 rounded-md shadow-md tracking-wide uppercase border border-blue 
            cursor-pointer ease-linear transition-all duration-150">
            <i class="fas fa-cloud-upload-alt fa-3x "></i>
            <span class="mt-2 text-base leading-normal">Select a file and click Upload</span>
            <input type='file' class="hidden" ref="file" v-on:change="handleFileUpload()"/>
            </label>
            <div class="flex flex-col items-center px-4 py-2 bg-white rounded-md shadow-md border border-emerald-700" v-if="this.file!=''">
                <span class="mt-2 text-base font-bold">Selected File : {{this.file.name}}</span>
            </div>
            <!-- <p class="w-full" v-if="this.file!=''">Selected File : {{this.file.name}}</p> -->
        </div>
        <div class="w-full lg:w-3/12 px-4">
            <button
            class="bg-emerald-500 mt-10 mr-4 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click="submitFile()"
            >Upload
            </button>

            <!-- <button v-if="doneUploading"
            class="bg-emerald-500 ml-2 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
            @click="toggleDf()">{{ hiddenDf ? "Show Data" : "Hide Data"}}
            </button> -->
            
        </div>
    </div>
    <div class="flex justify-around" id="uploadNewFile">
        <div v-if="doneUploading">
            <button class=" bg-red-400 mt-6 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" 
            @click="uploadNewFile()">
            Upload New File
            </button>
        </div>
        <div v-if="Object.keys(data_after_preprocessing).length > 0">
            <button class=" bg-emerald-500 mt-6 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded 
            shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" 
            @click="downloadCsvAfterPrep()">
            Download CSV File
            </button>
        </div>
    </div>
    <div>
        <!-- <div class="w-full lg:w-12/12" id="df1" v-if="!hiddenDf"> -->
        <div class="w-full lg:w-12/12" id="df1">
            <!-- <div id="df1" style="display:inline-block" v-if="!hiddenDf"></div>
            <div id="df1" style="display:none" v-else></div> -->
        </div>
    </div>
    
    <div class="flex flex-wrap py-4" 
    v-if="doneUploading"
    id="text_column" 
    ref="text_column">
        <div class="w-full lg:w-4/12 px-4" 
        >
            <p class="mt-2">Choose the column containing text </p>
        </div>
        <div class="w-full lg:w-8/12 px-4" >
            <select class="w-full rounded" v-model="textcol">
                <option v-for="column,index in df_columns" :value="column" :key="index">{{column}}</option>
            </select>
        </div>
    </div>
    <div class="py-4" id="preprocessing_techniques" 
    ref="preprocessing_techniques"  v-if="textcol !== null" >
        <techniques-checkbox 
        :prep_techniques="prep_techniques" 
        :submitTechniques="submitTechniques"/>
    </div> 
</template>

<script>
// import axios from 'axios';
// import Papa from '../../../node_modules/papaparse/papaparse.js';
import techniquesCheckbox from './techniquesChecbox.vue';
const dfd = require("danfojs");

export default {
    components: {
        techniquesCheckbox,
    },
    data() {
        return {
            file:"",
            doneUploading : false, 
            df:[],
            // data: {},
            textcol:null,
            df_columns:[],
            valid_techniques : false,
            params_text_preprocessing : {},
            header_style : {                   
                fill: { color: ['#50C98E'] },                    
                font: { family: "Arial", size: 15, color: "white" } ,
                width: 1200,
                },
            cell_style : { 
                line: { color: "black", width: 1 }                
                },    
        }
    },
    computed: {
        data() {
            return this.$store.getters['textprep/data'];
        },
        prep_techniques(){
            return this.$store.getters['textprep/prep_techniques'];
        }, 
        data_after_preprocessing(){
            return this.$store.getters['textprep/data_after_preprocessing'];
        }
    },
    mounted() {
        let externalScript = document.createElement('script');
        externalScript.setAttribute('src', 'https://cdn.plot.ly/plotly-2.2.0.min.js');
        document.head.appendChild(externalScript);
        // this.scroll()
    },
    methods:{
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
            this.$store.dispatch('textprep/saveFilename',this.file);            
        }, 
        uploadNewFile(){
            this.doneUploading = !this.doneUploading;
            this.$store.dispatch('textprep/resetState');
            this.file="";
            this.df=[];
            this.df_columns=[];
            this.$store.state.data_after_preprocessing={};
            console.log(this.params_text_preprocessing);
            this.textcol=null;
            this.prep_techniques.selected=[];
            this.valid_techniques = false;
            this.params_text_preprocessing={};
            
        },
        json_to_df(data) {
            const json_data = Object.values(data);
            const df = new dfd.DataFrame(json_data);
            return df
        },
        scroll(id) {  
            const el = document.getElementById(id);
            el.scrollIntoView({behavior: "smooth"});
        },
        async submitTechniques(){
            //Get textcol
            this.$store.dispatch('textprep/saveTextcol',this.textcol);
            console.log(this.textcol);
            // Get preprocessing techniques 
            this.$store.dispatch('textprep/getPrepTechniques');
            const selected_choices = this.prep_techniques.selected;
            console.log("Selected_choices " +  selected_choices);

            // Check preprocessing techniques
            this.$store.dispatch('textprep/checkTechniques'); 
            const valid_techniques = this.$store.state.textprep.valid_techniques;
            console.log( "Valid_techniques " +valid_techniques);

            // Set Params to Request 
            this.$store.dispatch('textprep/setParamsTextPreprocessing'); 
            const params_text_preprocessing = this.$store.state.textprep.params_text_preprocessing;
            console.log("params_text_preprocessing " + JSON.stringify(params_text_preprocessing));

            // Call Request 
            await this.$store.dispatch('textprep/getDataAfterPreprocessing'); 

            console.log(this.data_after_preprocessing);
            const df = new dfd.DataFrame(this.data_after_preprocessing);
            df.print();
            const sub_df = df.loc({ rows: [`:`], columns: ["text", "original_text"] });
            sub_df.plot("df1").table();
            this.scroll("uploadNewFile");

            this.prep_techniques.selected = [];
            console.log("Selected_choices " +  this.prep_techniques.selected);
            
        
        },
        async submitFile(){
            // Get Json data 
            await this.$store.dispatch('textprep/submitFile');
            console.log(this.data);
            this.doneUploading = true;
            
            //Display Dataframe 
            const df = new dfd.DataFrame(this.data);
            df.plot("df1").table({ header_style: this.header_style, cell_style: this.cell_style });
            
            const columns_df = Object.values(df.columns);
            console.log(columns_df);
            this.df_columns = columns_df;
            this.textcol = this.df_columns[0];
            if (this.doneUploading){
                this.scroll("text_column");
            }
            
         },
        downloadCsvAfterPrep(){
            let df = new dfd.DataFrame(this.data_after_preprocessing);
            dfd.to_csv(df, { fileName: "testOut.csv", download: true});
        }
    }, 
    
    
}
</script>
