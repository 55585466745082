export default{
    responses(state){
        return state.responses;
    },
    isLoading(state){
        return state.isLoading;
    },
    finish_collecting_tweets(state){
        return state.finish_collecting_tweets;
    }
}