import defaultState from './index.js';
export default{
    setParams(state,payload){
        state.params_twitter = payload
    },
    setQuery(state,payload){
        state.query = payload
    },
    saveResponse(state,payload) {
        state.responses = payload
    }, 
    resetParams(state){
        state.params_twitter = {
            "max_results":10,
        }
    }, 
    resetResponses(state){
        state.responses=[]
    }, 
    resetState (state) {
        Object.assign(state, defaultState)
    //     const s = defaultState();
    //     Object.keys(s).forEach(key => {
    //         state[key] = s[key]
    //     })
    }
    
}