<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <a class="text-blueGray-700 text-xl font-bold" href="javascript:void(0)">Search Twitter</a>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="submitSearch">
        <h6 class="text-blueGray-400 text-base mt-3 mb-6 font-bold uppercase">
          Connect To Twitter API
        </h6>
        <div class="flex flex-wrap">
            <!-- Bearer_Token section  -->
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                        for="bearer_token">
                        Bearer Token
                    </label>
                    <input 
                    type="password"
                    v-model="bearer_token"
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
                    id="bearer_token" 
                    :placeholder="bearer_token_value">
                </div>
            </div>
        </div>

        <!-- Words section -->
        <h6 class="text-blueGray-400 text-base mt-3 mb-6 font-bold uppercase">
          Words
        </h6>
        <div class="flex flex-wrap">
            <div v-for="(word_query,idx) in words_query" :key="idx"
            class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                <label
                class="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                :for="word_query.label"
                >
                    {{word_query.title}}
                </label>
                <input
                    type="text"
                    :id="word_query.label"
                    v-model="word_query.val"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
                </div>
            </div>

            <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                    <label
                    class="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                    for="languages"
                    >
                    Languages
                    </label>
                    <select class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-gray-200 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <option>All</option>
                        <option>French</option>
                        <option>English</option>
                    </select>
                </div>
            </div>
        </div>

          
        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-base mt-3 mb-6 font-bold uppercase">
          Accounts
        </h6>
        

        <div class="flex flex-wrap">
            <div v-for="(account_query,idx) in accounts_query"
            :key="idx" class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                    :for="account_query.label"
                >
                    {{account_query.title}}
                </label>
                <input
                    :id="account_query.label"
                    v-model="account_query.val"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
                </div>
            </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-base mt-3 mb-6 font-bold uppercase">
          Filters
        </h6>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                    for="include_rtw">
                    <input type="checkbox" class="form-checkbox" v-model="retweets" checked>
                    <span class="ml-2">Include Retweets</span>
                </label>
                </div>
            </div>

            <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                        for="max_tweets">
                        The total of tweets you want to collect
                    </label>
                    <input
                        type="number"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="1000"
                    />
                </div>
            </div>

            <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                        for="max_results">
                        Maximum tweets per call (from 10-500)
                    </label>
                    <input
                        type="number"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="500"
                    />
                </div>
            </div>

            
            <fields-twitter v-for="(options_field,idx) in options_fields" 
            :key="idx"
            :options_field="options_field"
            />
        </div>

        <h6 class="text-blueGray-400 text-base mt-3 mb-6 font-bold uppercase">
          Dates
        </h6>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                <label for="from_date" class="block uppercase text-blueGray-600 text-sm font-bold mb-2">From
                date</label>
                <input type="text" name="from_date" placeholder="YYYY-mm-dd" id="from_date"
                v-model="from_date"
                class="mt-1 focus:ring-indigo-500 text-gray-300  focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            </div>

            <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                    <label for="to_date" class="block uppercase text-blueGray-600 text-sm font-bold mb-2">To
                    date</label>
                    <input type="text" name="to_date" placeholder="YYYY-mm-dd" id="to-date"
                    v-model="to_date"
                    class="mt-1 focus:ring-indigo-500 text-gray-300  focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />   
                </div>
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4 py-10">
                <div class="relative w-full mb-3">
                    <button
                    class="uppercase absolute right-0
                    font-bold py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md ring  ring-emerald-500 ring-offset-4 bg-emerald-500 hover:bg-emerald-600 text-white focus:outline-none focus:ring-2  focus:ring-emerald-500">
                    Search
                    </button>
                </div>
            </div>
        </div>
        <!-- <p v-if="responses.length>1">{{responses}}</p> -->
        </form>
    </div>
  </div>

</template>


<script>
import fieldsTwitter from './fieldsTwitter.vue';
import Papa from '../../../../node_modules/papaparse/papaparse.js';
import flat from '../../../../node_modules/flat/index.js';
export default {
    components: {fieldsTwitter},
    computed: {
        options_fields() {
            return this.$store.state.twitter.options_fields;
        },
        words_query() {
            return this.$store.state.twitter.words_query;
        },
        accounts_query() {
            return this.$store.state.twitter.accounts_query;
        },
        from_date_value() {
            const date = new Date("2013-02-01");
            return new Date(date).toISOString()
        }, 
        responses(){
            return this.$store.getters['twitter/responses'];
        }, 
        bearer_token_value(){
            if (localStorage.getItem('bearer_token')) {
                return localStorage.getItem('bearer_token');
            }else {
                return "************************************";
            }
        }
    },
    data() {
        return {
            query:'',
            bearer_token:'',
            retweets:'',
            params_twitter : {},
            from_date:'',
            to_date:'',
            isLoading:false,
            finish_collecting_tweets:false,
            }
        },
        methods: {
            buildQuery() {
                // Build query from words
                for (var word_query of this.words_query) {
                    
                    if (word_query.label == "all_these_words" && word_query.val!=="") {
                        this.query += " " + word_query.val + " "
                    }
                    if (word_query.label == "any_of_these_words" && word_query.val!=="") {
                        const or_these_words = word_query.val.split(" ").join(" OR ")
                        this.query += "(" +or_these_words + ")"
                    }
                    if (word_query.label == "exact_phrase" && word_query.val!=="") {
                        this.query += ' "' + word_query.val + '" '
                    }
                    if (word_query.label == "none_of_these_words" && word_query.val!=="") {
                        const none_these_words = word_query.val.split(" ");
                        none_these_words.forEach((val, index) => 
                            none_these_words[index] = "-" + val);
                        this.query += none_these_words.join(" ");
                    }
                    if (word_query.label == "hashtags" && word_query.val!=="") {
                        if (word_query.val.length > 1) {
                            const q_hashtags = word_query.val.split(" ").join(" OR ");
                            this.query += ' (' + q_hashtags + ') '
                        }else {
                            const q_hashtags = word_query.val;
                            this.query += ' (' + q_hashtags + ') '
                        }
                    }
                }
                // Build Query from Accounts 
                for (var account_query of this.accounts_query) {
                    if(account_query.label == "from_account" && account_query.val!==""){
                        const list_accounts = account_query.val.split(" ");
                        list_accounts.forEach((val, index) => 
                            list_accounts[index] = "from:" + val);
                        const from_accounts = list_accounts.join(" OR ");
                        this.query += ' (' + from_accounts + ') ';
                    }
                    if(account_query.label == "to_account" && account_query.val!==""){
                        const list_accounts = account_query.val.split(" ");
                        list_accounts.forEach((val, index) => 
                            list_accounts[index] = "to:" + val);
                        const from_accounts = list_accounts.join(" OR ");
                        this.query += ' (' + from_accounts + ') ';
                    }
                    if(account_query.label == "mentionning_accounts" && account_query.val!==""){
                        const list_accounts = account_query.val.split(" ");
                        list_accounts.forEach((val, index) => 
                            list_accounts[index] = "@:" + val);
                        const from_accounts = list_accounts.join(" OR ");
                        this.query += ' (' + from_accounts + ') ';
                    }
                }
                // Build Query from retweets
                if (!this.retweets){
                    this.query += " -is:retweet "
                }
                console.log(this.query);
                this.$store.dispatch('twitter/setQuery',this.query);
                this.params_twitter["keyword"] = this.query;
            },
            getFields(){
                for (var options_field of this.options_fields) {
                    const checked = options_field.values.checked;
                    const selected_choices = options_field.values.selected;
                    const choices = options_field.values.choices;
                    // Loop checked => if checked => push value to selected 
                    checked.forEach((val,index) => {
                        if (val) {
                            selected_choices.push(choices[index]);
                        }
                    });
                    console.log(selected_choices);
                    const field_name = options_field.label;
                    if(selected_choices.length > 0 ){
                        console.log(selected_choices);
                        // const field_name = options_field.key_params;
                        this.params_twitter[field_name] = selected_choices.join(",");
                        options_field.values.selected = [];
                    }
                    
                }
            },
            getDates(){
                if (this.from_date !== "") {
                    this.params_twitter["start_time"]=new Date(this.from_date).toISOString()
                }
                if (this.to_date !== "") {
                    this.params_twitter["end_time"]=new Date(this.to_date).toISOString()
                }
            },
            submitSearch() {
                if (localStorage.getItem('bearer_token')){
                    this.params_twitter.bearer_token = localStorage.getItem('bearer_token');
                }
                else{
                    this.params_twitter.bearer_token = this.bearer_token;
                }
                // this.params_twitter.bearer_token = this.bearer_token;
                this.isLoading=true;
                this.buildQuery();
                this.getFields();
                this.getDates();
                //console.log(this.params_twitter);
                this.$store.dispatch('twitter/setParams',this.params_twitter);
                console.log(this.responses);
                console.log(this.$store.state.twitter.params_twitter);
                this.$store.dispatch('twitter/connectToEndPoint');
                this.finish_collecting_tweets=true;
                window.scrollTo(0, 0);
                // this.$store.dispatch('twitter/resetState');

                this.query="";
                this.$store.dispatch('twitter/resetParams');
                this.$store.dispatch('twitter/resetResponses');
                // 
                // this.params_twitter={};
                // this.isLoading=false;
            }, 
            downloadJson() {
                // credit: https://www.bitdegree.org/learn/javascript-download
                let text = JSON.stringify(this.responses, null, 4);
                let filename = 'results.json';
                let element = document.createElement('a');
                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);     
            },
            downloadCsv() {
                // credit: https://www.bitdegree.org/learn/javascript-download
                let filename = 'results.csv';
                // let text = flatten(this.responses);
                let text = Papa.unparse(this.responses.map(flat.flatten));
                console.log(text);
                
                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element); 
            },
        }
    }
</script>


